// frontend/src/components/OperationDashboardKPI.js

import React from 'react';
import { Box } from '@mui/material';
import Layout from './Layout';
import PowerBIReport from './PowerBIReport';

const OperationDashboardKPI = () => {
    return (
        <Layout>
            <Box sx={{ flexGrow: 1, width: '100%', height: '100%' }}>
                <PowerBIReport
                    reportKey="OperationDashboardKPI"
                    title="Operation Dashboard KPI"
                />
            </Box>
        </Layout>
    );
};

export default OperationDashboardKPI;
