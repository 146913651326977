// frontend/src/components/BagOrders.js

import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    TextField,
    Button,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
    Toolbar,
    Tooltip,
    IconButton,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { alpha } from '@mui/material/styles';
import { getOrders, addOrder, removeOrder } from '../services/api';
import { validateEAN13, validatePAN, validateResellerRefID } from '../utils/validation';
import Layout from './Layout';
import { useSnackbar } from '../contexts/SnackbarContext';
import CustomTable from './CustomTable'; // Import the CustomTable component

const BagOrders = () => {
    const { showSnackbar } = useSnackbar();
    const [orders, setOrders] = useState([]);
    const [orderToDelete, setOrderToDelete] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('ean13');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25); // Set default rows per page to 25
    const [dateTime, setDateTime] = useState('');

    // Form validation states
    const [ean13Error, setEAN13Error] = useState('');
    const [panError, setPanError] = useState('');
    const [resellerRefError, setResellerRefError] = useState('');

    // Instructions dialog state
    const [openInstructionsDialog, setOpenInstructionsDialog] = useState(false);

    useEffect(() => {
        const updateDateTime = () => {
            const today = new Date();
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            };
            setDateTime(today.toLocaleDateString('en-UK', options));
        };

        updateDateTime();
        const intervalId = setInterval(updateDateTime, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const loadOrders = async () => {
        try {
            const response = await getOrders();
            if (response.success) {
                setOrders(response.orders);
            } else {
                showSnackbar('Error loading orders.', 'error');
            }
        } catch (error) {
            showSnackbar('Error loading orders.', 'error');
        }
    };

    useEffect(() => {
        loadOrders();
    }, []);

    const handleRefresh = () => {
        loadOrders();
    };

// frontend/src/components/BagOrders.js

const handleAddOrder = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const newOrder = {
        ean13: formData.get('ean13').trim(), // Trim to remove any accidental spaces
        pan: formData.get('pan').trim(),
        reseller_ref: formData.get('reseller_ref').trim(),
    };

    // Validate EAN13: either empty or starts with '5'
    if (newOrder.ean13 && !validateEAN13(newOrder.ean13)) {
        setEAN13Error('EAN13 must start with "5" or be left empty.');
        return;
    } else {
        setEAN13Error('');
    }

    // Validate PAN
    if (!validatePAN(newOrder.pan)) {
        setPanError('PAN must start with 6 or 7 and cannot contain spaces.');
        return;
    } else {
        setPanError('');
    }

    // Validate ResellerRefNo
    if (!validateResellerRefID(newOrder.reseller_ref)) {
        setResellerRefError('ResellerRefNo must start with 11 alphanumeric characters followed by a hyphen and cannot contain spaces.');
        return;
    } else {
        setResellerRefError('');
    }

    try {
        const response = await addOrder(newOrder);
        if (response.success) {
            setOrders(response.orders);
            showSnackbar(response.alert_message, 'success');
            event.target.reset();
            document.getElementById('ean13').focus();
        } else {
            showSnackbar(response.alert_message, 'error');
        }
    } catch (error) {
        showSnackbar('Error adding order.', 'error');
    }
};


    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10)); // Corrected radix to 10
        setPage(0);
    };

    const sortedOrders = orders.slice().sort((a, b) => {
        if (orderBy === 'ean13') {
            return order === 'asc' ? a.EAN13.localeCompare(b.EAN13) : b.EAN13.localeCompare(a.EAN13);
        } else if (orderBy === 'pan') {
            return order === 'asc' ? a.PAN.localeCompare(b.PAN) : b.PAN.localeCompare(a.PAN);
        }
        return 0;
    });

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = orders.map((order) => order.Id); // Ensure 'Id' exists
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleRemoveOrder = () => {
        setOrderToDelete(selected);
        setOpenDialog(true);
    };

    const confirmDeleteOrder = async () => {
        setOpenDialog(false);
        if (selected.length > 0) {
            try {
                const response = await removeOrder(selected); // Pass the array of selected IDs
                if (response.success) {
                    setOrders(orders.filter(order => !selected.includes(order.Id))); // Remove deleted orders from the UI
                    showSnackbar(response.alert_message, 'success');
                    setSelected([]);
                } else {
                    showSnackbar(response.alert_message, 'error');
                }
            } catch (error) {
                showSnackbar('Error removing order.', 'error');
            }
        }
    };

    // Define columns for CustomTable (Removed 'id' column)
    const columns = [
        { id: 'EAN13', label: 'EAN13', minWidth: 150, align: 'left', sortable: true },
        { id: 'PAN', label: 'PAN', minWidth: 150, align: 'left', sortable: true },
        { id: 'Transaction Time Stamp', label: 'Transaction Time Stamp', minWidth: 180, align: 'left', sortable: true },
        { id: 'Value', label: 'Value', minWidth: 100, align: 'right', sortable: true },
        { id: 'Store ID', label: 'Store ID', minWidth: 100, align: 'left', sortable: true },
        { id: 'Retailer ID', label: 'Retailer ID', minWidth: 120, align: 'left', sortable: true },
        { id: 'ResellerRefNo', label: 'ResellerRefNo', minWidth: 150, align: 'left', sortable: true },
        { id: 'CreatedDate', label: 'Created Date', minWidth: 150, align: 'left', sortable: true },
        { id: 'ExportedCSV', label: 'Exported CSV', minWidth: 150, align: 'left', sortable: false },
        // Removed the 'id' column to hide it from the table
    ];

    // Prepare data for CustomTable (Keep 'id' field for internal use)
    const tableData = sortedOrders.map(order => ({
        EAN13: order.EAN13,
        PAN: order.PAN,
        'Transaction Time Stamp': order['Transaction Time Stamp'],
        Value: order.Value,
        'Store ID': order['Store ID'],
        'Retailer ID': order['Retailer ID'],
        ResellerRefNo: order.ResellerRefNo,
        CreatedDate: order.CreatedDate,
        ExportedCSV: order.ExportedCSV ? 'Yes' : 'No',
        id: order.Id, // Keep 'id' field for row identification
    }));

    return (
        <Layout>
            <Box sx={{ padding: 3 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                    Buy A Gift Report
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    {dateTime}
                </Typography>

                {/* Add New Order Form Wrapped in Paper */}
                <Paper elevation={3} sx={{ p: 2, mt: 3 }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
                        <Typography variant="h6">Add New Order</Typography>
                        <Tooltip title="Show Instructions">
                            <IconButton
                                color="primary"
                                onClick={() => setOpenInstructionsDialog(true)}
                            >
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <form id="barcodeForm" onSubmit={handleAddOrder}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                            <TextField
    label="EAN13 Barcode"
    id="ean13"
    name="ean13"
    fullWidth
    onChange={(e) => {
        const value = e.target.value.trim();
        if (value === '' || validateEAN13(value)) {
            setEAN13Error('');
        } else {
            setEAN13Error('EAN13 must start with "5" or be left empty.');
        }
    }}
    error={!!ean13Error}
    helperText={ean13Error}
    // Remove the 'required' attribute to make it optional
/>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="PAN Barcode"
                                    name="pan"
                                    fullWidth
                                    onChange={(e) => setPanError(validatePAN(e.target.value) ? '' : 'PAN must start with 6 or 7 and cannot contain spaces.')}
                                    error={!!panError}
                                    helperText={panError}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="ResellerRefNo"
                                    name="reseller_ref"
                                    fullWidth
                                    onChange={(e) => setResellerRefError(validateResellerRefID(e.target.value) ? '' : 'ResellerRefNo must start with 11 alphanumeric characters followed by a hyphen and cannot contain spaces.')}
                                    error={!!resellerRefError}
                                    helperText={resellerRefError}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary" fullWidth>
                                    Add Order
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>

                {/* Orders Table Wrapped in Paper and CustomTable */}
                <Paper elevation={3} sx={{ p: 2, mt: 3 }}>
                    <Toolbar
                        sx={[
                            {
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 1 },
                            },
                            selected.length > 0 && {
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                            },
                        ]}
                    >
                        {selected.length > 0 ? (
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                color="inherit"
                                variant="subtitle1"
                                component="div"
                            >
                                {selected.length} selected
                            </Typography>
                        ) : (
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                variant="h6"
                                id="tableTitle"
                                component="div"
                            >
                                Orders
                            </Typography>
                        )}

                        {selected.length > 0 ? (
                            <Tooltip title="Delete">
                                <IconButton onClick={handleRemoveOrder}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Refresh list">
                                <IconButton onClick={handleRefresh}>
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Toolbar>

                    {/* CustomTable Component */}
                    <CustomTable
                        columns={columns}
                        data={tableData}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        selectable
                        selectedRows={selected}
                        onRowClick={(id) => handleClick(null, id)}
                        selectAllHandler={handleSelectAllClick}
                        rowId="id" // Set to 'id' to match the tableData
                    />
                </Paper>

                {/* Delete Confirmation Dialog */}
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Do you really want to delete these orders? This process cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDialog(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={confirmDeleteOrder} color="error">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Instructions Dialog */}
                <Dialog open={openInstructionsDialog} onClose={() => setOpenInstructionsDialog(false)}>
                    <DialogTitle>Daily Operation Instructions</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography variant="body1">
                                1. Reload the page before starting by clicking on the company logo or "Refresh List" button.
                            </Typography>
                            <Typography variant="body1">
                                2. Scan the EAN13, PAN barcode, and order number (found on the MP packing slip) into the system.
                            </Typography>
                            <Typography variant="body1">
                                3. No need to press "Export to CSV" as the process is fully automated.
                            </Typography>
                            <Typography variant="body1">
                                4. The CSV file is automatically exported and sent to Moonpig at 22:00 every night.
                            </Typography>
                            <Box
                                component={Paper}
                                elevation={2}
                                sx={{
                                    padding: 2,
                                    backgroundColor: '#ffe6e6', // Light red background
                                    borderLeft: '6px solid #ff6347', // Orange/red accent border
                                    marginTop: 2,
                                }}
                            >
                                <Typography variant="h6" sx={{ color: '#ff6347', fontWeight: 'bold' }}>
                                    Attention
                                </Typography>
                                <Typography variant="body1" sx={{ marginTop: 1 }}>
                                    i. <strong>DO NOT USE</strong> the old Excel input procedure anymore.
                                </Typography>
                                <Typography variant="body1" sx={{ marginTop: 1 }}>
                                    ii. <strong>No spaces</strong> are allowed in any input fields.
                                </Typography>
                                <Typography variant="body1" sx={{ marginTop: 1 }}>
                                    iii. After adding an order, <strong>confirm whether the total number of orders is correct</strong>.
                                </Typography>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenInstructionsDialog(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Layout>
    );

};

export default BagOrders;
