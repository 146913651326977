// frontend/src/components/ReportsDashboard.js

import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardActions, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';

const reports = [
    {
        key: 'OperationDashboardKPI',
        title: 'Operation Dashboard KPI',
        description: 'Tracks key performance indicators for operations.',
        route: '/operation-dashboard-kpi',
    },
    {
        key: 'KeyEventsOrderStatistics',
        title: 'Key Events Order Statistics Report',
        description: 'Analyzes order statistics related to key events.',
        route: '/key-events-order-statistics',
    }
    // Add more reports as needed
];

const ReportsDashboard = () => {
    const navigate = useNavigate();

    const handleNavigate = (route) => {
        navigate(route);
    };

    return (
        <Layout>
            <Box sx={{ padding: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Power BI Reports
                </Typography>
                <Grid container spacing={3}>
                    {reports.map(report => (
                        <Grid item xs={12} sm={6} md={4} key={report.key}>
                            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        {report.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {report.description}
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ marginTop: 'auto' }}>
                                    <Button size="small" onClick={() => handleNavigate(report.route)}>View Report</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Layout>
    );
};

export default ReportsDashboard;
