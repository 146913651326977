// frontend/src/components/PowerBIReport.js

import React, { useEffect, useState, useRef } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import axios from 'axios';
import { CircularProgress, Box, Typography, Button, Stack } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

const PowerBIReport = ({ reportKey, title }) => {
    const [embedData, setEmbedData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const reportRef = useRef(null);

    // Define a media query for mobile devices
    const isMobile = useMediaQuery({ maxWidth: 767 });

    useEffect(() => {
        const fetchEmbedData = async () => {
            try {
                const token = sessionStorage.getItem('token'); // Ensure the token is correctly stored

                if (!token) {
                    throw new Error('Authentication token not found.');
                }

                const response = await axios.get(`/api/powerbi/embed-report?report=${reportKey}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setEmbedData(response.data);
                setLoading(false);
            } catch (err) {
                console.error(`Error fetching embed data for report "${reportKey}":`, err);
                setError('Failed to load dashboard. Please try again later.');
                setLoading(false);
            }
        };

        fetchEmbedData();
    }, [reportKey]);

    // Handler for Print
    const handlePrint = async () => {
        if (reportRef.current) {
            const report = reportRef.current;
            try {
                await report.print();
            } catch (err) {
                console.error('Error printing report:', err);
                alert('Failed to print the report.');
            }
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <Typography variant="h6" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Typography variant="h4" gutterBottom>
                    {title}
                </Typography>
                <Stack direction="row" spacing={1} sx={{ mt: { xs: 2, sm: 0 } }}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handlePrint}
                        sx={{
                            backgroundColor: 'grey.500',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'grey.700',
                            },
                        }}
                    >
                        Print
                    </Button>
                    {/* Add more buttons if needed */}
                </Stack>
            </Box>
            <Box sx={{ flexGrow: 1, width: '100%', height: '100%' }}>
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report', // Supported types: report, dashboard, tile, etc.
                        id: embedData.reportId,
                        embedUrl: embedData.embedUrl,
                        accessToken: embedData.embedToken,
                        tokenType: models.TokenType.Embed,
                        permissions: models.Permissions.All, // Adjust permissions as needed
                        settings: {
                            filterPaneEnabled: false, // Hide the filter pane
                            navContentPaneEnabled: true, // Show/hide navigation pane as needed
                            layoutType: isMobile ? models.LayoutType.MobilePortrait : models.LayoutType.Master, // Use valid layout types
                        },
                    }}
                    cssClassName="report-style-class"
                    style={{ height: '100%', width: '100%' }} // Ensure full size
                    getEmbeddedComponent={(embeddedReport) => {
                        console.log('Embedded Report:', embeddedReport);
                        reportRef.current = embeddedReport; // Save the report instance for later use
                    }}
                />
            </Box>
        </Box>
    );
};

PowerBIReport.propTypes = {
    reportKey: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default PowerBIReport;
