// frontend/src/components/KeyEventsOrderStatistics.js

import React from 'react';
import { Box } from '@mui/material';
import Layout from './Layout';
import PowerBIReport from './PowerBIReport';

const KeyEventsOrderStatistics = () => {
    return (
        <Layout>
            <Box sx={{ flexGrow: 1, width: '100%', height: '100%' }}>
                <PowerBIReport
                    reportKey="KeyEventsOrderStatistics"
                    title="Key Events Order Statistics Report"
                />
            </Box>
        </Layout>
    );
};

export default KeyEventsOrderStatistics;
