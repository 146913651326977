// frontend/src/utils/validation.js

/**
 * Validates the strength of a password.
 * 
 * Requirements:
 * - At least 8 characters long.
 * - Contains at least one lowercase letter.
 * - Contains at least one uppercase letter.
 * - Contains at least one digit.
 * - May include special characters from the specified set.
 * 
 * @param {string} password - The password string to validate.
 * @returns {boolean} - Returns true if the password meets all criteria, else false.
 */
export const validatePassword = (password) => {
    // This regex ensures at least:
    // 1 lowercase letter, 1 uppercase letter, and 1 number.
    // Allows special characters but does not require them.
    // The special characters are explicitly listed to avoid issues with characters like ^.
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&£^#(){}\[\]:;<>,.?/~_+\-=|\\]{8,}$/;
    return passwordRegex.test(password);
};

/**
 * Validates the EAN13 barcode.
 * 
 * Allows the EAN13 to be either:
 * - An empty string (i.e., not provided).
 * - Starts with '5' followed by exactly 12 digits, adhering to the EAN-13 format.
 * 
 * @param {string} ean13 - The EAN13 barcode string to validate.
 * @returns {boolean} - Returns true if the EAN13 is valid or empty, else false.
 */
export const validateEAN13 = (ean13) => {
    // Allow empty string or EAN13 starting with '5' followed by 12 digits
    if (ean13 === null || ean13 === undefined || ean13.trim() === '') {
        return true;
    }
    const ean13Regex = /^5\d{12}$/;
    return ean13Regex.test(ean13);
};

/**
 * Validates the PAN barcode.
 * 
 * Requirements:
 * - Must start with either '6' or '7'.
 * - Must not contain any spaces.
 * 
 * @param {string} pan - The PAN barcode string to validate.
 * @returns {boolean} - Returns true if the PAN is valid, else false.
 */
export const validatePAN = (pan) => {
    return (pan.startsWith('6') || pan.startsWith('7')) && !pan.includes(' ');
};

/**
 * Validates the Reseller Reference ID.
 * 
 * Requirements:
 * - Must start with exactly 11 alphanumeric characters.
 * - Must be followed by a hyphen ('-').
 * - Must contain additional alphanumeric characters after the hyphen.
 * - Must not contain any spaces.
 * 
 * @param {string} resellerRefID - The Reseller Reference ID to validate.
 * @returns {boolean} - Returns true if the ResellerRefID is valid, else false.
 */
export const validateResellerRefID = (resellerRefID) => {
    const resellerRefPattern = /^[0-9A-Za-z]{11}-[0-9A-Za-z]+$/;
    return resellerRefPattern.test(resellerRefID) && !resellerRefID.includes(' ');
};
